defineDs('DanskeSpil/Domain/Lotto/Scripts/Templates/LottoSystem/Accordion',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render'
  ],
  function (m, Render) {

    // View:
    var Accordion = function (controller, type, data) {

      // Variables:
      var breakAt = 50;
      var d = controller.d;
      var game = controller.game();
      var render = Render();
      var rowSpan = (data.index * breakAt) + breakAt;
      var system = game.systemName();
      var maxRows = parseInt(system.split('-')[1], 10);
      var systemType = system ? system.trim().slice(0, 1) : null;

      // Render:
      render.ready.then(function (nodes) {
        var $body = nodes['body'];
        var $header = nodes['header'];
        var rows = nodes['row'];

        if (!$body.rendered) {
          if (controller.property('AccordionTabType' + data.index) !== type) {
            controller.property('AccordionTabType' + data.index, type);
            controller.property('AccordionToggled' + data.index, false);
          }

          if (typeof controller.property('AccordionToggled' + data.index) === 'undefined') {
            controller.property('AccordionToggled' + data.index, false);
          }

          $header.$element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            controller.property('AccordionToggled' + data.index, !controller.property('AccordionToggled' + data.index));

            m.redraw();
          });

          if (maxRows < breakAt && type === 'key-tab') {
            controller.property('AccordionToggled' + data.index, true);

            setTimeout(function () {
              m.redraw();
            }, 500);
          }
        }

        var height = 0;
        var singleHeight = $DS(rows[1].$element).outerHeight();

        for (var i = 0; i < rows.length; i++) {
          height += singleHeight;
        }

        controller.property('AccordionHeight' + data.index, Math.ceil(height * 1.2));
      });

      // View:
      return m('div', { class: 'accordion-wrapper' + (controller.property('AccordionToggled' + data.index) ? ' active' : ''), config: render.depend() }, [
        m('div', { class: 'accordion-header', config: render.depend('header') }, [
          m('span', { class: 'title' }, type === 'key-tab' ? d('LottoSystem/KeyTab/Row', { number: ((data.index * breakAt) + 1) + '-' + (rowSpan > maxRows ? maxRows : ((data.index * breakAt) + breakAt)) }) : (systemType === 'M' && parseInt(data.additional, 10) ? d('LottoSystem/ProfitGuaranteeTab/WinningNumbersAdditional', { additional: data.additional, number: data.number }) : d('LottoSystem/ProfitGuaranteeTab/WinningNumbers', { number: data.number }))),
          m('svg', { class: 'icon ' }, [
            m('use', { class: 'arrow-down', href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-down-arrow' }),
            m('use', { class: 'close', href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-up-arrow' })
          ])
        ]),

        // Key tab:
        type === 'key-tab' ? [
          m('div', { class: 'accordion-body', config: render.depend('body'), style: controller.property('AccordionToggled' + data.index) ? 'max-height: ' + controller.property('AccordionHeight' + data.index) + 'px;' : null }, [
            m('table', { class: 'simple-custom-table', config: render.depend('table') }, [
              m('thead', [
                m('tr', [
                  m('th', d('LottoSystem/KeyTab/TableHeaderRow')),
                  m('th', { config: render.depend('row') }, d('LottoSystem/KeyTab/TableHeaderNumber'))
                ])
              ]),
              m('tbody', function () {
                return data.rows.map(function (row, index) {
                  return m('tr', [
                    m('td', d('LottoSystem/KeyTab/Row', { number: index + ((data.index * breakAt) + 1) })),
                    m('td', { config: render.depend('row') }, row.numbers ? row.numbers.map(function (number) {
                      return number.number;
                    }).join(', ') : row.join(', '))
                  ]);
                });
              }())
            ])
          ])

          // Winnings:
        ] : [
          systemType === 'M' ? [
            m('div', { class: 'accordion-body', config: render.depend('body'), style: controller.property('AccordionToggled' + data.index) ? 'max-height: ' + controller.property('AccordionHeight' + data.index) + 'px;' : null }, [
              m('table', { class: 'simple-accordion-table', config: render.depend('table') }, [
                m('tbody', [
                  data.prizes.map(function (prize, index) {
                    return m('tr', [
                      m('td', d('LottoSystem/ProfitGuaranteeTab/NumberOfPrizes', { number: index + 1 })),
                      m('td', { config: render.depend('row') }, prize)
                    ]);
                  })
                ]),
              ]),
            ])
          ] : [
            m('div', { class: 'accordion-body', config: render.depend('body'), style: controller.property('AccordionToggled' + data.index) ? 'max-height: ' + controller.property('AccordionHeight' + data.index) + 'px;' : null }, [
              m('table', { class: 'accordion-table', config: render.depend('table') }, [
                m('thead', [
                  m('tr', { config: render.depend('row') }, [
                    data.columns.map(function (column) {
                      return m('td', [
                        column.correct,
                        m('span', d('LottoSystem/ProfitGuaranteeTab/CorrectNumbers'))
                      ]);
                    }),
                    m('td', d('LottoSystem/ProfitGuaranteeTab/Frequency'))
                  ])
                ]),
                m('tbody', [
                  function () {
                    var columnsAmount = data.columns.length;
                    var dom = [];
                    var rowsAmount = data.columns[0].values.length;

                    for (var i = 0; i < rowsAmount; i++) {
                      var innerDom = [];

                      for (var j = 0; j < columnsAmount; j++) {
                        innerDom.push(m('td', data.columns[j].values[i]));
                      }

                      innerDom.push(m('td', data.frequency[i]));

                      dom.push(m('tr', { config: render.depend('row') }, innerDom));
                    }

                    return dom;
                  }()
                ])
              ])
            ])
          ]
        ]
      ]);
    };

    // Public functions:
    return Accordion;

  });
