defineDs('DanskeSpil/Domain/Lotto/Scripts/Campaigns/Components/SummerMultiClient', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/SaturdayLottoApi',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
  'DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
  'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoClassic/DeleteOverlay',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/MultiClientDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/MultiClient/MultiClient'
], function (Component, AccountIntegration, Api, LottoDictionary, LottoInfo, LottoGame, DeleteOverlay, mcDictionary, MultiClient) {
  Component('lottosaturday-som-multi-client', [AccountIntegration, LottoDictionary, LottoInfo, LottoGame], function (m, ignoreRoute, settings, property, element) {
    this.d = LottoDictionary.get;
    this.settings = settings;
    this.property = property;
    this.element = element;
    this.gameType = 'Lotto';
    this.infoFeed = m.prop(LottoInfo.saturday());
    this.isFeedDown = m.prop(LottoInfo.isFeedDown());
    this.getLightningDefaultRowCount = m.prop(this.infoFeed().getLightningDefaultRowCount());
    this.getLightningOptions = m.prop(this.infoFeed().getLightningOptions);
    this.api = function () {
      return Api;
    }.bind(this);
    this.deleteOverlayForClassicRow = DeleteOverlay;

    this.campaign = m.prop('Sommerlotto');
    var setItem = mcDictionary.setItem.bind(null, this.d);

    var summerLottoDictionaries = {
      closedGame: {
        noOpenDrawHeader: setItem('LottoClosed/FrontpageLightning/NoOpenDrawHeader'),
        noOpenDrawText1: setItem('LottoClosed/FrontpageLightning/NoOpenDrawText_1'),
        noOpenDrawText2: setItem('LottoClosed/FrontpageLightning/NoOpenDrawText_2'),
        gameNotAvailableHeader: setItem('LottoClosed/FrontpageLightning/GameNotAvailableHeader'),
        gameNotAvailableText1: setItem('LottoClosed/FrontpageLightning/GameNotAvailableText_1'),
        gameNotAvailableText2: setItem('LottoClosed/FrontpageLightning/GameNotAvailableText_2')
      },
      campaignInformOverlay: {
        title: setItem('Campaigns/SummerMultiClient/InformOverlay/Title'),
        body: setItem('Campaigns/SummerMultiClient/InformOverlay/Description'),
        confirmButton: setItem('Campaigns/SummerMultiClient/InformOverlay/ConfirmButton')
      },
      game: {
        descriptionSingle: setItem('Campaigns/SummerMultiClient/GameStep/DescriptionSingle'),
        description: setItem('Campaigns/SummerMultiClient/GameStep/Description')
      },
      draws: {
        drawSublabelSingleWeekSingleTicket: setItem('Campaigns/SummerMultiClient/DrawsStep/DrawSublabelSingleWeekSingleTicket'),
        drawSublabelSingleWeek: setItem('Campaigns/SummerMultiClient/DrawsStep/DrawSublabelSingleWeek'),
        drawSublabelSingleTicket: setItem('Campaigns/SummerMultiClient/DrawsStep/DrawSublabelSingleTicket'),
        drawSublabel: setItem('Campaigns/SummerMultiClient/DrawsStep/DrawSublabel'),
        drawSublabelNoTickets: setItem('Campaigns/SummerMultiClient/DrawsStep/DrawSublabelNoTickets')
      },
      joker: {
        wednesdayJokerLabel: setItem('MultiClient/JokerStep/WednesdayJokerLabel'),
        saturdayJokerLabel: setItem('MultiClient/JokerStep/SaturdayJokerLabel'),
        bothJokerLabel: setItem('MultiClient/JokerStep/BothDrawsDaysLabel')
      },
      receipt: {
        subscribeReminder: {
          keepRemindMe: setItem('LottoReceiptStep/SubscribeReminder/KeepRemindMe'),
          remindMe: setItem('LottoReceiptStep/SubscribeReminder/RemindMe'),
          errorUserUnauthorized: setItem('LottoReceiptStep/SubscribeReminder/ErrorUserUnauthorized'),
          error: setItem('LottoReceiptStep/SubscribeReminder/Error'),
          successRemindMe: setItem('LottoReceiptStep/SubscribeReminder/SuccessRemindMe'),
          successDontRemindMe: setItem('LottoReceiptStep/SubscribeReminder/SuccessDontRemindMe')
        }
      }
    };

    mcDictionary.setDictionary.bind(this.d)(summerLottoDictionaries);

    // Component
    MultiClient(m, LottoGame, this);
  });
});
