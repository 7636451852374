defineDs('DanskeSpil/Domain/Lotto/Scripts/Templates/WinningNumbers/Numbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (m, Utils) {

    // Template:
    var Numbers = function (controller, numbers, type) {
      // Variables:
      var d = controller.d;
      var weekNumber;
      var headLine;

      var number = function (int) {
        return m('li', [
          m('svg', { class: 'number-icon' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoCommonIcons.svg#icon-numbershape' })
          ]),
          m('span', int)
        ]);
      };

      var numbersContruct = [];
      if (numbers) {
        numbersContruct = numbers.map(function (num) {
          return number(num);
        });
      }

      if (controller.selectedDrawDate && controller.selectedDrawDate()) {
        weekNumber = Utils.getWeekNumber(controller.selectedDrawDate());
      } else {
        weekNumber = controller.completedDrawsDates && controller.completedDrawsDates() ? Utils.getWeekNumber(controller.completedDrawsDates()[0]) || null : null;
      }

      if (weekNumber) {
        if (type === 'bonus') {
          headLine = d('WinningNumbers/BonusNumbersWithWeek', { week: weekNumber });
        } else if (type === 'super') {
          headLine = d('WinningNumbers/SuperNumberWithWeek', { week: weekNumber });
        } else if (type === 'joker') {
          headLine = d('WinningNumbers/JokerNumbersWithWeek', { week: weekNumber });
        } else {
          headLine = d('WinningNumbers/WinningNumbersWithWeek', { week: weekNumber });
        }
      } else {
        if (type === 'bonus') {
          headLine = d('WinningNumbers/BonusNumbers');
        } else if (type === 'super') {
          headLine = d('WinningNumbers/SuperNumber');
        } else if (type === 'joker') {
          headLine = d('WinningNumbers/JokerNumbers');
        } else {
          headLine = d('WinningNumbers/WinningNumbers');
        }
      }

      // Return:
      return [
        m('h2', { class: 'numbers-headline' }, headLine),
        m('ul', { class: type || '' }, numbersContruct)
      ];

    };

    // Public functions:
    return Numbers;

  });
