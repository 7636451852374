defineDs('DanskeSpil/Domain/Lotto/Scripts/Templates/ShakeOverlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoUtils'
  ],
  function (m, Render, Storage, Utils) {

    // Template:
    var ShakeOverlay = function (controller) {

      // Check:
      if (!Utils.isMobile()) {
        return;
      }

      // Variables:
      var d = controller.d;
      var day = 86400;
      var days = 7;
      var game = controller.game();
      var playType = game.playType();
      var id = playType + 'LottoGame';
      var lastTime = Storage.get(id);
      var render = Render();

      if (parseInt(lastTime, 10) > ((Date.now() / 1000) - (day * days))) {
        return;
      }

      // Render:
      render.ready.then(function (nodes) {
        var overlay = nodes['overlay'];

        if (!overlay.rendered) {
          var $element = overlay.$element;

          $DS(nodes['phone'].$element).on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function () {
            Storage.set(id, Date.now());
          });

          $element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            Storage.set(id, Date.now());

            $element.classList.add('remove');
          });
        }
      });

      // View:
      return m('div', { class: 'shake-overlay-wrapper', config: render.depend('overlay') }, [
        m('div', { class: 'phone-whrapper' }, [
          m('div', { class: 'phone-shape-whrapper', config: render.depend('phone') }, [
            m('svg', { class: 'phone-shape' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-phone' })
            ]),
            m('span', { class: 'shake-lines' }),
            m('span', { class: 'shake-lines' })
          ]),
          m('div', { class: 'phone-shadow' })
        ]),
        m('div', { class: 'shake-overlay-text' }, d('ShakeOverlay/ShakeOverlayText'))
      ]);
    };

    // Public functions:
    return ShakeOverlay;

  });
