defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/LottoLightning',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
    'DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/PurchaseBar',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoLightning/RotarySelector',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/JokerOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/SubscriptionValidationOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo'
  ],
  function (Component, Params, LottoDictionary, LottoInfo, LottoGame, PurchaseBar, RotarySelector, ErrorOverlay, JokerOverlay, SubscriptionValidationOverlay, Overlay, JackpotInfo) {

    // Component:
    Component('lotto-lightning', [LottoInfo, LottoDictionary], function (m, route, settings, property) {

      // Variables:
      var infoFeed = LottoInfo.saturday();
      var isFeedDown = LottoInfo.isFeedDown();

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.d = LottoDictionary.get;
          this.property = property;

          if (!isFeedDown) {
            this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
            this.personalized = m.prop(settings.personalized || false);
            this.game = m.prop(LottoGame.setupGame({ gameInstanceId: this.gameInstanceId(), numberOfDraws: this.personalized() ? settings.numberofdraws : undefined, playType: 'Lightning', rowsToGenerate: this.personalized() ? settings.rows : infoFeed.getLightningDefaultRowCount() }));
            this.errorOverlay = m.prop(new Overlay(ErrorOverlay('lotto-lightning-game-overlay', { header: this.d('GenerateRowsErrorOverlay/Header'), body: this.d('GenerateRowsErrorOverlay/Body'), dismiss: this.d('GenerateRowsErrorOverlay/Dismiss') })));
            this.jokerOverlay = m.prop(new Overlay(JokerOverlay(this.game(), 'lotto-lightning-game-overlay', this.d, LottoInfo.data())));
            this.subscriptionValidationOverlay = m.prop(new Overlay(SubscriptionValidationOverlay('lotto-lightning-game-overlay subscription-validation-overlay', { header: this.d('SubscriptionValidationOverlay/Header'), body: this.d('SubscriptionValidationOverlay/Body'), dismiss: this.d('SubscriptionValidationOverlay/Dismiss') })));
            this.rows = this.game().rowsToGenerate;
            this.rowsOptions = m.prop(infoFeed.getLightningOptions());

            if (this.personalized() && window.dataLayer) {
              window.dataLayer.push({
                event: 'gaEvent',
                eventSyntax: {
                  action: 'spil_klient',
                  category: 'personalisering',
                  label: 'Spiltype: lotto-lightning, Antal_raekker: ' + this.game().rowsToGenerate() + ', Antal_uger: ' + this.game().numberOfDraws()
                }
              });
            }

            // Functions:
            this.purchase = function () {
              var game = this.game();
              var self = this;

              // Abort purchase and show subscription validation overlay, if subscription is chosen but not valid
              if (game.numberOfDraws() === 0 && !game.isSubscriptionValid()) {
                this.subscriptionValidationOverlay().show();
                return;
              }

              this.jokerOverlay().show(function () {
                game.prepareConfirm().then(function (confirmUrl) {
                  if (game.numberOfDraws() === 0) {
                    game.sendToSubscription();
                  } else {
                    location.href = confirmUrl;
                  }
                }, function () {
                  self.errorOverlay().show();
                });
              });
            }.bind(this);

          }
        },

        view: function (controller) {
          return [
            m('div', { class: 'lotto-section lotto-game-section purchase-bar-container' + (settings.topManchete || settings.bottomManchete ? ' manchete' : '') + (settings.topManchete ? ' top' : '') + (settings.bottomManchete ? ' bot' : '') }, [
              m('div', { class: 'lotto-content-wrapper' }, [
                function () {
                  if (isFeedDown || infoFeed.noOpenDraw()) {
                    // Feed down or no open draw, content
                    return m('div', { class: 'game-not-available-wrapper' }, [
                      // ICON
                      settings.iconUrl.length > 0 ? m('img', { class: 'not-available-icon', src: settings.iconUrl }) : '',
                      isFeedDown ? [
                        m('h2', { class: 'headline' }, controller.d('LottoClosed/FrontpageLightning/GameNotAvailableHeader')),
                        m('h3', { class: 'subheadline' }, controller.d('LottoClosed/FrontpageLightning/GameNotAvailableText_1')),
                        m('h3', { class: 'subheadline' }, controller.d('LottoClosed/FrontpageLightning/GameNotAvailableText_2'))
                      ] : [
                        m('h2', { class: 'headline' }, controller.d('LottoClosed/FrontpageLightning/NoOpenDrawHeader')),
                        m('h3', { class: 'subheadline' }, controller.d('LottoClosed/FrontpageLightning/NoOpenDrawText_1')),
                        m('h3', { class: 'subheadline' }, controller.d('LottoClosed/FrontpageLightning/NoOpenDrawText_2'))
                      ]
                    ]);
                  } else {
                    // Regular lightning content
                    return m('div', [
                      m('h2', { class: 'headline' }, JackpotInfo.getDynamicFeed(controller.d('LottoLightning/Headline'))),
                      m('h3', { class: 'subheadline' }, JackpotInfo.getDynamicFeed(controller.d('LottoLightning/ChooseRows'))),
                      RotarySelector(controller.rowsOptions, controller.rows, controller.d('LottoLightning/Row' + (controller.rows() !== 1 ? 's' : ''))),
                      PurchaseBar(controller)
                    ]);
                  }
                }()
              ])
            ])
          ];
        }
      };

      // Routes:
      route('/', root);

    });

  });
