defineDs('DanskeSpil/Domain/Lotto/Scripts/Templates/LottoSystem/Row',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/RowNumbers',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/RowNumbersFooter'
  ],
  function (m, RowNumbers, RowNumbersFooter) {

    // Template:
    var Row = function (controller) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var d = controller.d;
      var game = controller.game();
      var numbers = game.getRow(currentRow).numbers.length;

      // Functions:
      var rowClass = function () {
        var className = 'row-container row-number-1 in-focus';

        if (controller.property('animate') === 'shake') {
          className += ' shake-state';
        }

        return className;
      };

      var rowConfig = function ($element) {
        $element = $DS($element);

        $element.unbind();

        $element.on('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function () {
          var animate = controller.property('animate');

          if (/shake/.test(animate) && !/shake-end/.test(animate)) {
            controller.property('animate', controller.property('animate') + ' shake-end');

            m.redraw();
          }
        });
      };

      // View:
      return m('div', { class: 'rows-container' }, [

        // Row container:
        m('div', { class: rowClass(), config: rowConfig }, [
          m('div', { class: 'row-header' }, [
            (game.getSystemNumberAmount() - numbers) !== 0 ? d('LottoSystem/RowHeader', { number: (game.getSystemNumberAmount() - numbers) }) : d('LottoSystem/RowHeaderDescriptionAllNumbersSelected'),
            m('span', d('LottoSystem/RowDescription', { rows: game.getSystemRowAmount() }))
          ]),
          m('div', { class: 'number-picker-container' }, [
            RowNumbers(controller, 0),
            RowNumbersFooter(controller, 0)
          ])
        ]),
      ]);

    };

    // Public functions:
    return Row;

  });
