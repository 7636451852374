defineDs('DanskeSpil/Domain/Lotto/Scripts/Templates/WinningNumbers/Drawing',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/WinningNumbers/Numbers',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/WinningNumbers/Results'
  ],
  function (m, Render, Numbers, Results) {

    // Template:
    var Drawing = function (controller, data, type) {
      var render = Render();

      var onsdagsLotto = (data.superNumber && data.superNumber.length > 0);
      // Variables:
      var brand = {
        lotto: {
          logo: function () {
            if (onsdagsLotto) {
              return m('svg', { class: 'onsdags-lotto-logo' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoCommonIcons.svg#icon-onsdagslotto_logo' })
              ]);
            } else {
              return m('svg', { class: 'lotto-logo' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoCommonIcons.svg#icon-lotto_logo_vindertal' })
              ]);
            }
          },
          class: 'lotto-winning-numbers'
        },
        joker: {
          logo: function () {
            return [
              m('svg',  { class: 'joker-hat' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoCommonIcons.svg#icon-joker_hue_for_red_bg' })
              ]),
              m('svg', { class: 'joker-text' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoCommonIcons.svg#icon-joker_logo_vindertal' })
              ])];
          },
          class: 'joker-winning-numbers'
        }
      };

      render.ready.then(function (nodes) {
        var featuredWinners = nodes['featured-winners'];

        if (!featuredWinners.rendered) {
          var $element = featuredWinners.$element;

          $element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            $element.classList.toggle('hide');
          });
        }
      });

      var featuredWinners = function (winners, game) {
        if (!winners) return null;

        return m('div', { class: 'featured-winners hide', config: render.depend('featured-winners') }, [
          m('div', { class: 'header' }, [
            controller.d('WinningNumbers/FeaturedWinners/' + game),
            m('svg', { class: 'icon-arrow' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-up-arrow' })
            ])
          ]),
          m('div', { class: 'text' }, [
            m.trust(winners)
          ])
        ]);
      };

      // Return:
      return type === 'lotto' ? // LOTTO
        m('div', { class: [
          'winning-numbers-element',
          brand.lotto.class,
          (data.checkViewport && typeof data.checkViewport === 'function') ? (data.checkViewport(brand.lotto.class) ? 'winning-numbers-element--fade' : '') : ''
        ].join(' ')
        }, [
          m('div', { class: 'logo' }, [
            brand.lotto.logo()
          ]),
          m('div', { class: 'numbers' }, [
            Numbers(controller, data.winningNumbers),
            onsdagsLotto ?
              m('div', { class: 'inline-numbers' }, [
                m('div', { class: 'number-box' }, [
                  Numbers(controller, data.bonusNumbers, 'bonus')
                ]),
                m('div', { class: 'number-box' }, [
                  Numbers(controller, data.superNumber, 'super')
                ])
              ])
              :
              Numbers(controller, data.bonusNumbers, 'bonus')
          ]),
          m('div', { class: 'results' }, [
            Results(controller, data.prizes, type)
          ]),
          featuredWinners(data.winners, controller.game())
        ])
        : // JOKER
        m('div', { class: [
          'winning-numbers-element',
          brand.joker.class,
          (data.checkViewport && typeof data.checkViewport === 'function') ? (data.checkViewport(brand.joker.class) ? 'winning-numbers-element--fade' : '') : ''
        ].join(' ')
        }, [
          m('div', { class: 'logo' }, [
            brand.joker.logo()
          ]),
          m('div', { class: 'numbers' }, [
            Numbers(controller, data.winningNumbers, 'joker')
          ]),
          m('div', { class: 'results' }, [
            Results(controller, data.prizes, 'joker')
          ]),
          featuredWinners(data.winners, controller.jokerGame())
        ]);
    };

    // Public functions:
    return Drawing;

  });
