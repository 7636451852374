defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/LottoDrawExperience',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoUtils',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/SaturdayLottoApi'
  ],
  function (Component, LottoDictionary, LottoUtils, SaturdayLottoApi) {

    // Prototyping:
    CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
      if (width < 2 * radius) {
        radius = width / 2;
      }

      if (height < 2 * radius) {
        radius = height / 2;
      }

      this.beginPath();

      this.moveTo(x + radius, y);

      this.arcTo(x + width, y, x + width, y + height, radius);
      this.arcTo(x + width, y + height, x, y + height, radius);
      this.arcTo(x, y + height, x, y, radius);
      this.arcTo(x, y, x + width, y, radius);

      this.closePath();

      return this;
    };

    // Component:
    Component('lotto-draw-experience', [LottoDictionary], function (m, route) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.abstractCategory = m.prop(32);
          this.ballCategory = m.prop(4);
          this.ballLayer1 = m.prop(8);
          this.ballLayer2 = m.prop(16);
          this.balls = m.prop([]);
          this.boundaryCategory = m.prop(2);
          this.boundaryTubeCapCategory = m.prop(128);
          this.boundaryTubeCategory = m.prop(64);
          this.d = LottoDictionary.get;
          this.defaultCategory = m.prop(1);
          this.diamondBoundaryWidth = m.prop(50);
          this.diamondHeight = m.prop(500);
          this.diamondWidth = m.prop(300);
          this.drawDate = m.prop();
          this.extractedIndex = m.prop(0);
          this.loading = m.prop(true);
          this.selectedBalls = m.prop([]);
          this.winningNumbers = m.prop([]);

          requireDs([
            'DanskeSpil/Framework/NumberGames/Scripts/Framework/Matter',
            'DanskeSpil/Framework/NumberGames/Scripts/Framework/PolyDecomp'
          ], function (Matter) {

            // Variables:
            this.engine = m.prop(Matter.Engine.create());

            // Front faces:
            this.frontFace1 = m.prop([{
              x: -20,
              y: 0
            }, {
              x: 60,
              y: 20
            }, {
              x: 215,
              y: 20
            }, {
              x: 295,
              y: 0
            }, {
              x: 215,
              y: -20
            }, {
              x: 60,
              y: -20
            }]);
            this.frontFace2 = m.prop([{
              x: -20,
              y: 0
            }, {
              x: -70,
              y: 95
            }, {
              x: 30,
              y: 120
            }, {
              x: 60,
              y: 20
            }]);
            this.frontFace3 = m.prop([{
              x: 0,
              y: 0
            }, {
              x: -30,
              y: 100
            }, {
              x: 190,
              y: 100
            }, {
              x: 160,
              y: 0
            }]);
            this.frontFace4 = m.prop([{
              x: 20,
              y: 0
            }, {
              x: 70,
              y: 95
            }, {
              x: -30,
              y: 120
            }, {
              x: -60,
              y: 20
            }]);
            this.frontFace5 = m.prop([{
              x: 0,
              y: 0
            }, {
              x: 165,
              y: 340
            }, {
              x: 180,
              y: 340
            }, {
              x: 100,
              y: 25
            }]);
            this.frontFace6 = m.prop([{
              x: 0,
              y: 0
            }, {
              x: 80,
              y: 315
            }, {
              x: 140,
              y: 315
            }, {
              x: 220,
              y: 0
            }]);
            this.frontFace7 = m.prop([{
              x: 0,
              y: 25
            }, {
              x: -80,
              y: 340
            }, {
              x: -62,
              y: 340
            }, {
              x: 100,
              y: 0
            }]);

            // Back faces:
            this.backFace1 = m.prop([{
              x: 0,
              y: 0
            }, {
              x: -50,
              y: 95
            }, {
              x: 50,
              y: 60
            }, {
              x: 80,
              y: -20
            }]);
            this.backFace2 = m.prop([{
              x: 0,
              y: 0
            }, {
              x: 30,
              y: 80
            }, {
              x: -188,
              y: 80
            }, {
              x: -158,
              y: 0
            }]);
            this.backFace3 = m.prop([{
              x: 0,
              y: 0
            }, {
              x: 50,
              y: 95
            }, {
              x: -50,
              y: 60
            }, {
              x: -80,
              y: -20
            }]);
            this.backFace4 = m.prop([{
              x: 0,
              y: 0
            }, {
              x: 165,
              y: 340
            }, {
              x: 190,
              y: 340
            }, {
              x: 100,
              y: -35
            }]);
            this.backFace5 = m.prop([{
              x: 0,
              y: 0
            }, {
              x: 90,
              y: 374
            }, {
              x: 130,
              y: 374
            }, {
              x: 216,
              y: 0
            }]);
            this.backFace6 = m.prop([{
              x: -15,
              y: -35
            }, {
              x: -101,
              y: 340
            }, {
              x: -75,
              y: 340
            }, {
              x: 85,
              y: 0
            }]);

            // Tubes:
            this.tubeBoundaryVisible = m.prop(false);
            this.tubeFace = m.prop(Matter.Bodies.rectangle(304, 390, 44, 260, {
              isStatic: true,
              collisionFilter: {
                mask: this.abstractCategory()
              },
              render: {
                fillStyle: function () {
                  var drawing = document.createElement('canvas');
                  var ctx = drawing.getContext('2d');
                  var grd = ctx.createLinearGradient(100, 100, 800, 100);

                  grd.addColorStop(0, 'rgba(209, 240, 255, .0)');
                  grd.addColorStop(1, 'rgba(209, 240, 255, .5)');

                  return grd;
                }(),
                strokeStyle: 'rgba(180, 229, 253, .3)',
                lineWidth: 1,
                zIndex: 3
              }
            }));
            this.tubeGroupIndex = m.prop(5);
            this.tubePart1 = m.prop(Matter.Bodies.rectangle(224, 190, 10, 180, {
              angle: -Math.PI * 0.2
            }));
            this.tubePart2 = m.prop(Matter.Bodies.rectangle(380, 190, 10, 180, {
              angle: Math.PI * 0.2
            }));
            this.tubePart3 = m.prop(Matter.Bodies.rectangle(279, 420, 10, 320));
            this.tubePart4 = m.prop(Matter.Bodies.rectangle(330, 420, 10, 320));
            this.tubePart5 = m.prop(Matter.Bodies.rectangle(300, 300, 60, 10));
            this.tubePart5Sensor = m.prop(Matter.Bodies.rectangle(300, 300, 60, 10, {
              isSensor: true
            }));
            this.tubezIndex = m.prop(4);

            // Bottom tubes:
            this.bottomTubeSide1 = m.prop(Matter.Bodies.rectangle(278, 550, 10, 70, {
              angle: -Math.PI * 0.015
            }));
            this.bottomTubeSide2 = m.prop(Matter.Bodies.rectangle(327, 550, 10, 70, {
              angle: Math.PI * 0.015
            }));
            this.bottomTubeSide3 = m.prop(Matter.Bodies.rectangle(303, 582, 100, 10, {
              label: 'bottomTubeSide3'
            }));
            this.bottomTubeSensor = m.prop(Matter.Bodies.rectangle(303, 582, 100, 10, {
              isSensor: true
            }));
            this.bottomTubeCompound = m.prop(Matter.Body.create({
              parts: [this.bottomTubeSide1(), this.bottomTubeSide2(), this.bottomTubeSide3(), this.bottomTubeSensor()],
              isStatic: true,
              collisionFilter: {
                group: this.tubeGroupIndex()
              },
              render: {
                visible: this.tubeBoundaryVisible()
              }
            }));

            this.tubeCompound = m.prop(Matter.Body.create({
              parts: [this.tubePart1(), this.tubePart2(), this.tubePart3(), this.tubePart4(), this.tubePart5(), this.tubePart5Sensor()],
              isStatic: true,
              collisionFilter: {
                group: this.tubeGroupIndex()
              },
              render: {
                zIndex: this.tubezIndex(),
                visible: this.tubeBoundaryVisible()
              }
            }));

            // Pistons:
            this.piston1 = m.prop(Matter.Bodies.circle(250, 573, 60, {
              isStatic: true,
              collisionFilter: {
                category: this.boundaryCategory()
              },
              render: {
                visible: false
              }
            }));
            this.piston2 = m.prop(Matter.Bodies.circle(300, 573, 60, {
              isStatic: true,
              collisionFilter: {
                category: this.boundaryCategory()
              },
              render: {
                visible: false
              }
            }));
            this.piston3 = m.prop(Matter.Bodies.circle(350, 573, 60, {
              isStatic: true,
              collisionFilter: {
                category: this.boundaryCategory()
              },
              render: {
                visible: false
              }
            }));

            // Stands:
            this.stand = m.prop(Matter.Bodies.rectangle(302, 560, 180, 80, {
              isStatic: true,
              collisionFilter: {
                mask: this.abstractCategory()
              },
              render: {
                zIndex: 13,
                sprite: {
                  texture: function () {
                    var drawing = document.createElement('canvas');

                    drawing.height = 80;
                    drawing.width = 180;

                    var context = drawing.getContext('2d');

                    context.beginPath();

                    context.moveTo(30, 0);

                    context.lineTo(150, 0);
                    context.lineTo(180, 80);
                    context.lineTo(0, 80);

                    context.stroke();

                    context.fillStyle = '#2A3640';

                    context.fill();

                    context.closePath();

                    context.save();

                    context.globalCompositeOperation = 'destination-out';

                    context.beginPath();

                    context.arc(90, 40, 20, 0, 2 * Math.PI, false);

                    context.fill();

                    context.closePath();

                    return drawing.toDataURL('image/png');
                  }()
                }
              }
            }));
            this.standBack = m.prop(Matter.Bodies.circle(302, 560, 30, {
              isStatic: true,
              collisionFilter: {
                mask: this.abstractCategory()
              },
              render: {
                fillStyle: '#202A34',
                zIndex: 0
              }
            }));
            this.standBottom = m.prop(Matter.Bodies.rectangle(298, 690, 400, 180, {
              isStatic: true,
              collisionFilter: {
                mask: this.abstractCategory()
              },
              chamfer: {
                radius: 10
              },
              render: {
                fillStyle: '#950001'
              }
            }));

            // Stand slopes:
            this.standSlopeBoundary1 = m.prop(Matter.Bodies.rectangle(290, 615, 10, 80, {
              angle: -Math.PI * 0.15
            }));
            this.standSlopeBoundary2 = m.prop(Matter.Bodies.rectangle(350, 642, 10, 150, {
              angle: -Math.PI * 0.43
            }));
            this.standSlopeBoundary3 = m.prop(Matter.Bodies.rectangle(465, 680, 10, 140, {
              angle: Math.PI * 0.15
            }));
            this.standSlopeBoundary4 = m.prop(Matter.Bodies.rectangle(300, 735, 10, 500, {
              angle: Math.PI * 0.44,
              label: 'standSlopeBoundary4'
            }));
            this.standSlopeBoundary5 = m.prop(Matter.Bodies.rectangle(110, 740, 10, 100, {
              angle: -Math.PI * 0.1
            }));
            this.standSlopeBoundaryCompound = m.prop(Matter.Body.create({
              parts: [this.standSlopeBoundary1(), this.standSlopeBoundary2(), this.standSlopeBoundary3(), this.standSlopeBoundary4(), this.standSlopeBoundary5()],
              isStatic: true,
              collisionFilter: {
                group: this.tubeGroupIndex()
              },
              render: {
                zIndex: 15,
                visible: false
              }
            }));
            this.standSlope1 = m.prop(Matter.Bodies.rectangle(355, 625, 150, 30, {
              isStatic: true,
              angle: Math.PI * 0.07,
              collisionFilter: {
                mask: this.abstractCategory()
              },
              chamfer: {
                radius: [0, 15, 5, 30]
              },
              render: {
                fillStyle: 'rgba(180, 229, 253, .25)',
                strokeStyle: 'rgba(209, 240, 255, .4)',
                lineWidth: 1,
                zIndex: 15
              }
            }));
            this.standSlope2 = m.prop(Matter.Bodies.rectangle(290, 720, 370, 30, {
              isStatic: true,
              angle: -Math.PI * 0.06,
              collisionFilter: {
                mask: this.abstractCategory()
              },
              chamfer: {
                radius: [5, 5, 30, 15]
              },
              render: {
                fillStyle: 'rgba(180, 229, 253, .25)',
                strokeStyle: 'rgba(209, 240, 255, .4)',
                lineWidth: 1,
                zIndex: 15
              }
            }));
            this.standSlopePin = m.prop(Matter.Bodies.circle(390, 699, 7, {
              isStatic: true,
              collisionFilter: {
                mask: this.abstractCategory()
              },
              render: {
                fillStyle: 'rgba(180, 229, 253, .05)',
                strokeStyle: 'rgba(209, 240, 255, .1)',
                lineWidth: 1,
                zIndex: 2
              }
            }));

            // World:
            this.world = m.prop(this.engine().world);

            // Functions:
            this.abstractPath = function (x, y, vertices, zIndex, color, stroke) {
              var drawing = document.createElement('canvas');
              var ctx = drawing.getContext('2d');
              var grd = ctx.createLinearGradient(100, 100, 400, 1000);

              grd.addColorStop(0, 'rgba(209, 240, 255, .0)');
              grd.addColorStop(1, 'rgba(209, 240, 255, .35)');

              color = color ? color : grd;
              stroke = stroke ? stroke : 'rgba(180, 229, 253, .6)';

              return Matter.Bodies.fromVertices(x, y, vertices, {
                isStatic: true,
                collisionFilter: {
                  mask: this.abstractCategory()
                },
                render: {
                  position: {
                    x: 0,
                    y: 0
                  },
                  fillStyle: color,
                  strokeStyle: stroke,
                  zIndex: zIndex,
                  lineWidth: 1
                }
              });
            }.bind(this);

            this.boundaryPath = function (x, y, path) {
              var self = this;
              var vertices = Matter.Vertices.fromPath(path);

              return Matter.Bodies.fromVertices(x, y, vertices, {
                isStatic: true,
                collisionFilter: {
                  category: self.boundaryCategory()
                },
                render: {
                  visible: false,
                  fillStyle: '#0f0',
                  strokeStyle: '#f00',
                  lineWidth: 1
                }
              });
            }.bind(this);

            this.init = function () {
              var ballsComposite = Matter.Composite.create();
              var counter = 0;
              var counter1 = 0;
              var counter2 = 0;
              var counter3 = 0;
              var diamondBoundaryWidth = this.diamondBoundaryWidth();
              var diamondHeight = this.diamondHeight();
              var diamondWidth = this.diamondWidth();
              var engine = this.engine();
              var facesComposite = Matter.Composite.create();
              var render = Matter.Render.create({
                canvas: document.getElementById('lotto-draw-experience-canvas'),
                engine: this.engine(),
                options: {
                  height: 800,
                  width: 600,
                  background: 'transparent',
                  wireframes: false,
                  showAngleIndicator: false
                }
              });
              var mainPistonCounter = 0;
              var topPartH = diamondHeight / 3;
              var bottomPartH = topPartH * 2;
              var topDiamondBoundary = '0 ' + topPartH +
                ' ' + diamondBoundaryWidth + ' ' + topPartH +
                ' ' + (diamondBoundaryWidth + 50) + ' ' + (diamondBoundaryWidth + 20) +
                ' ' + (diamondBoundaryWidth + 130) + ' ' + diamondBoundaryWidth +
                ' ' + (diamondBoundaryWidth + diamondWidth - 20) + ' ' + diamondBoundaryWidth +
                ' ' + (diamondBoundaryWidth + diamondWidth + 65) + ' ' + (diamondBoundaryWidth + 20) +
                ' ' + (diamondBoundaryWidth + diamondWidth + 120) + ' ' + (topPartH) +
                ' ' + ((diamondBoundaryWidth * 2) + diamondWidth + 120) + ' ' + (topPartH) +
                ' ' + (diamondBoundaryWidth + diamondWidth + 90) + ' 0' +
                ' ' + (diamondBoundaryWidth + 20) + ' 0';
              var bottomDiamondBoundary = '0 0' +
                ' ' + diamondBoundaryWidth + ' 0' +
                ' ' + (diamondBoundaryWidth + 165) + ' ' + (bottomPartH + 5) +
                ' ' + (diamondBoundaryWidth + diamondWidth - 45) + ' ' + (bottomPartH + 5) +
                ' ' + (diamondBoundaryWidth + diamondWidth + 118) + ' ' + 0 +
                ' ' + ((diamondBoundaryWidth * 2) + diamondWidth + 120) + ' ' + 0 +
                ' ' + (diamondBoundaryWidth + diamondWidth) + ' ' + (bottomPartH + diamondBoundaryWidth) +
                ' ' + ((diamondBoundaryWidth * 2) + 30) + ' ' + (bottomPartH + diamondBoundaryWidth);
              var world = this.world();
              var frontFace1Body = this.abstractPath(302, 94, this.frontFace1(), 10, 'rgba(209, 240, 255, .1)');
              var backFace1Body = this.abstractPath(162, 130, this.backFace1(), 1, 'rgba(209, 240, 255, .15)', 'rgba(180, 229, 253, .1)');
              var backFace2Body = this.abstractPath(303, 117, this.backFace2(), 1, 'rgba(209, 240, 255, .11)', 'rgba(180, 229, 253, .15)');
              var backFace3Body = this.abstractPath(443, 130, this.backFace3(), 1, 'rgba(209, 240, 255, .15)', 'rgba(180, 229, 253, .1)');
              var backFace4Body = this.abstractPath(194, 314, this.backFace4(), 1, 'rgba(209, 240, 255, .15)', 'rgba(180, 229, 253, .1)');
              var backFace5Body = this.abstractPath(303, 300, this.backFace5(), 1, 'rgba(209, 240, 255, .1)', 'rgba(180, 229, 253, .1)');
              var backFace6Body = this.abstractPath(412, 314, this.backFace6(), 1, 'rgba(209, 240, 255, .15)', 'rgba(180, 229, 253, .1)');
              var frontFace2Body = this.abstractPath(162, 155, this.frontFace2(), 10);
              var frontFace3Body = this.abstractPath(302, 167, this.frontFace3(), 10);
              var frontFace4Body = this.abstractPath(442, 155, this.frontFace4(), 10);
              var frontFace5Body = this.abstractPath(189, 327, this.frontFace5(), 10);
              var frontFace6Body = this.abstractPath(302, 342, this.frontFace6(), 10);
              var frontFace7Body = this.abstractPath(415, 329, this.frontFace7(), 10);
              var self = this;

              var diamondFaces = [
                frontFace1Body,
                frontFace2Body,
                frontFace3Body,
                frontFace4Body,
                frontFace5Body,
                frontFace6Body,
                frontFace7Body,
                backFace1Body,
                backFace2Body,
                backFace3Body,
                backFace4Body,
                backFace5Body,
                backFace6Body
              ];

              for (var i = 0; i < 36; i++) {
                this.balls().push(new function (x, y, index) {
                  x = x ? x : 0;
                  y = y ? y : 0;

                  var radius = 18;
                  var ballCategory = 4;
                  var ballLayer1 = 8;
                  var ballLayer2 = 16;
                  var ballLayer = (index % 2) === 0 ? ballLayer1 : ballLayer2;
                  var boundaryCategory = 2;

                  var options = {
                    ballNumber: (index + 1),
                    isSelected: false,
                    label: 'numberBall',
                    lastSetCollision: null,
                    friction: .0001,
                    restitution: 0.5,
                    density: .005,
                    collisionFilter: {
                      category: ballCategory,
                      mask: boundaryCategory || ballLayer,
                      group: ((index % 3) + 1)
                    },
                    render: {
                      zIndex: 5,
                      sprite: {
                        texture: function () {
                          var drawing = document.createElement('canvas');

                          drawing.width = radius * 2;
                          drawing.height = radius * 2;

                          var context = drawing.getContext('2d');

                          context.beginPath();
                          context.fillStyle = ['#0A4C94', '#F5D235', '#056042', '#D10F17'][Math.floor(index / 9) % 4];
                          context.arc(radius, radius, radius, 0, 2 * Math.PI, false);
                          context.fill();
                          context.closePath();

                          context.beginPath();
                          context.roundRect(6, 6, 24, 24, 5);
                          context.fillStyle = '#000';
                          context.fill();
                          context.closePath();

                          context.beginPath();
                          context.font = '14px "Open Sans"';
                          context.textAlign = 'center';
                          context.textBaseline = 'middle';
                          context.fillStyle = '#fff';
                          context.fillText(index + 1, radius, radius);
                          context.fill();
                          context.closePath();

                          context.beginPath();
                          context.roundRect(13, 26, 10, 1, 1);
                          context.fillStyle = '#fff';
                          context.fill();
                          context.closePath();

                          context.beginPath();
                          var gradient2 = context.createRadialGradient(radius, radius, (radius - (radius - 5)), radius, radius, radius);

                          gradient2.addColorStop(0, 'rgba(5, 14, 58, 0)');
                          gradient2.addColorStop(1, 'rgba(5, 14, 58, 0.35)');

                          context.arc(radius, radius, radius, 2 * Math.PI, false);
                          context.fillStyle = gradient2;
                          context.fill();
                          context.closePath();

                          context.beginPath();
                          var gradient = context.createRadialGradient(radius + 4, radius, 3, radius, (radius - (radius / 2)), radius);

                          gradient.addColorStop(0, 'rgba(255, 255, 255, .35)');
                          gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

                          context.arc(radius, radius, radius, 2 * Math.PI, false);
                          context.fillStyle = gradient;
                          context.fill();
                          context.closePath();

                          return drawing.toDataURL('image/png');
                        }()
                      }
                    }
                  };

                  return Matter.Bodies.circle(x, y, radius, options);
                }(230 + (50 * Math.floor(i / 9)), (100 + (38 * (i % 9))), i));
              }

              // Add boundaries to the canvas:
              Matter.World.add(world, this.boundaryPath(diamondWidth, topPartH / 2, topDiamondBoundary));
              Matter.World.add(world, this.boundaryPath(diamondWidth - 2, (diamondHeight - diamondBoundaryWidth - 8), bottomDiamondBoundary));

              // Add front and back faces:
              Matter.Composite.add(facesComposite, diamondFaces);

              Matter.World.add(world, facesComposite);

              // Add the pistons:
              var piston1 = this.piston1();
              var piston2 = this.piston2();
              var piston3 = this.piston3();

              Matter.World.add(world, [piston1, piston2, piston3]);

              Matter.Events.on(engine, 'beforeUpdate', function () {
                if (mainPistonCounter < 4000) {
                  mainPistonCounter += 1;
                  counter1 += 1;
                  counter2 += 1;
                  counter3 += 1;

                  // Every 2 seconds:
                  if (counter1 >= 60 * 2) {
                    Matter.Body.setVelocity(piston1, {
                      x: -10,
                      y: -12
                    });

                    counter1 = 0;
                  }

                  // Every 1 second:
                  if (counter2 >= 60 * 1) {
                    Matter.Body.setVelocity(piston2, {
                      x: 10,
                      y: -12
                    });

                    counter2 = 0;
                  }

                  // Every 1.5 second:
                  if (counter3 >= 60 * 1.5) {
                    Matter.Body.setVelocity(piston3, {
                      x: 10,
                      y: -12
                    });

                    counter3 = 0;
                  }
                } else {
                  Matter.Body.setVelocity(piston1, {
                    x: 0,
                    y: 0
                  });

                  Matter.Body.setVelocity(piston2, {
                    x: 0,
                    y: 0
                  });

                  Matter.Body.setVelocity(piston3, {
                    x: 0,
                    y: 0
                  });
                }
              });

              // Add stands and stand slopes:
              Matter.World.add(world, [this.standBack(), this.stand(), this.standBottom()]);
              Matter.World.add(world, this.standSlopeBoundaryCompound());
              Matter.World.add(world, [this.standSlope1(), this.standSlope2()]);

              // Add the slope pin:
              Matter.World.add(world, this.standSlopePin());

              // Hook up the select number logic:
              Matter.Events.on(engine, 'beforeUpdate', function () {
                counter += 1;

                // Every 6.5 seconds:
                if (counter >= 60 * 6.5) {
                  self.selectNumber(engine);

                  // Reset counter:
                  counter = 0;
                }
              });

              // Add tubes:
              Matter.World.add(world, [this.tubeCompound(), this.bottomTubeCompound(), this.tubeFace()]);

              Matter.Events.on(engine, 'collisionStart', function (event) {
                var pairs = event.pairs;

                for (var i = 0, j = pairs.length; i != j; ++i) {
                  var pair = pairs[i];

                  if (pair.bodyB.label === 'numberBall' && pair.bodyB.isSelected) {
                    if (pair.bodyA.label === 'standSlopeBoundary4') {
                      pair.bodyB.lastSetCollision = 'standSlopeBoundary4';
                    }

                    if (pair.bodyA.label === 'bottomTubeSide3') {
                      pair.bodyB.render.zIndex = 14;
                    }
                  }

                  if (pair.bodyA === self.tubePart5Sensor()) {
                    setTimeout(function () {
                      Matter.Body.setPosition(self.tubePart5(), {
                        x: 240,
                        y: 300
                      });

                      setTimeout(function () {
                        Matter.Body.setPosition(self.tubePart5(), {
                          x: 300,
                          y: 300
                        });
                      }, 500);
                    }, 1500);
                  }

                  if (pair.bodyA === self.bottomTubeSensor()) {
                    setTimeout(function () {
                      Matter.Body.setPosition(self.bottomTubeSide3(), {
                        x: 200,
                        y: 582
                      });

                      setTimeout(function () {
                        Matter.Body.setPosition(self.bottomTubeSide3(), {
                          x: 303,
                          y: 582
                        });
                      }, 500);
                    }, 1500);
                  }
                }
              });

              // Add the balls:
              Matter.Composite.add(ballsComposite, this.balls());

              // Add all of the bodies to the world:
              Matter.World.add(world, ballsComposite);

              // Run the engine:
              Matter.Engine.run(engine);

              // Run the renderer:
              Matter.Render.run(render);

            }.bind(this);

            this.forceMove = function (body, endX, endY, pct) {
              // dx is the total distance to move in the X direction:
              var dx = endX - body.position.x;

              // dy is the total distance to move in the Y direction:
              var dy = endY - body.position.y;

              // Use dx and dy to calculate where the current [x,y] is at a given percent:
              var x = body.position.x + ((dx * pct) / 60);
              var y = body.position.y + ((dy * pct) / 60);

              Matter.Body.setPosition(body, {
                x: x,
                y: y
              });
            }.bind(this);

            this.selectNumber = function () {
              var engine = this.engine();
              var extractedIndex = this.extractedIndex();
              var bodies = Matter.Composite.allBodies(engine.world);
              var self = this;
              var winningNumbers = this.winningNumbers();

              for (var i = 0; i < bodies.length; i++) {
                var body = bodies[i];

                if (!body.isStatic && body.ballNumber) {
                  if (body.ballNumber === (winningNumbers[this.extractedIndex()])) {
                    var currentBall = body;

                    currentBall.collisionFilter.mask = this.abstractCategory();
                    currentBall.collisionFilter.group = 5;
                    currentBall.friction = 0.0001;
                    currentBall.restitution = 0.01;
                    currentBall.isSelected = true;

                    var pct = 0;
                    var moveBall = true;

                    this.selectedBalls().push(currentBall);

                    Matter.Events.on(engine, 'beforeUpdate', function () {
                      if (moveBall && pct < 61) {
                        pct = pct + 1;
                        var targetX = 300;
                        var targetY = 220;

                        self.forceMove(currentBall, targetX, targetY, pct);

                        if (pct === 60) {
                          currentBall.collisionFilter.mask = self.defaultCategory();
                          currentBall.friction = .3;
                          currentBall.restitution = 0;
                          currentBall.density = .1;
                          currentBall.frictionAir = 0;
                          currentBall.collisionFilter.group = 5;
                          currentBall.render.zIndex = 2;

                          if (extractedIndex < winningNumbers.length) {
                            self.extractedIndex(extractedIndex + 1);

                            pct = 0;
                          }

                          moveBall = false;
                        }
                      }

                      if (self.extractedIndex() > 7) {
                        setTimeout(function () {
                          self.standSlopePin().collisionFilter.group = self.tubeGroupIndex();
                          self.standSlopePin().render.fillStyle = 'rgba(255, 255, 255, .4)';
                          self.standSlopePin().render.strokeStyle = 'rgba(255, 255, 255, .5)';
                        }, 2000);
                      }

                      self.selectedBalls().forEach(function (ball) {
                        var motion = (ball.speed * ball.speed) + (ball.angularSpeed * ball.angularSpeed);
                        var isResting = motion < 0.1;

                        if (ball.lastSetCollision === 'standSlopeBoundary4' && isResting) {
                          ball.friction = .00001;
                          ball.isStatic = true;
                          Matter.Body.setAngle(ball, 0);
                        }
                      });
                    });
                  }
                }
              }
            }.bind(this);

            // Context:
            SaturdayLottoApi.getWinningNumbersLotto().then(function (data) {
              var winningNumbers = data.lottoSaturday.winningNumbers;

              winningNumbers.push(data.lottoSaturday.bonusNumber);

              this.drawDate(data.lottoSaturday.date);
              this.loading(false);
              this.winningNumbers(winningNumbers);

              this.init();

              m.redraw();
            }.bind(this));

          }.bind(this));

        },

        view: function (controller) {
          return controller.loading() ? null : m('h1', { class: 'experience-header' }, controller.d('WinningNumbers/DateOptionPrefix') + ' ' + LottoUtils.formatISO8601(controller.drawDate() + ' 00:00:00', { includeTime: false }));
        }
      };

      // Routes:
      route('/', root);

    });

  });
