defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/LottoSystem',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
    'DanskeSpil/Domain/Lotto/Scripts/Components/GameClients/LottoSystemClient'
  ],
  function (Component, LottoDictionary, LottoInfo, LottoSystemClient) {

    // Component:
    Component('lotto-system', [LottoDictionary, LottoInfo], function (m, route, settings, property) {

      var infoFeed = LottoInfo.saturday();

      // Feed check:
      if (LottoInfo.isFeedDown() || infoFeed.noOpenDraw()) {
        if (!window.sitecoreEnv && location.search.indexOf('avoid_redirect') == -1) {
          location.href = '/lotto/notavailable';
        }

        return;
      }

      // Components:
      var client = LottoSystemClient(m, settings, property);

      // Routes:
      route('/', client);

    });

  });
