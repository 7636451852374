defineDs('DanskeSpil/Domain/Lotto/Scripts/Templates/LottoSystem/InfoTab',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary'
  ],
  function (m, LottoDictionary) {


    // Template:
    var InfoTab = function (controller) {

      // Variables:
      var d = controller.d;
      var game = controller.game();
      var system = game.systemName();
      var systemType = system ? system.trim().slice(0, 1) : null;
      var rows = game.getSystemRowAmount();
      var numbers = game.getSystemNumberAmount();

      /**
        * Goes through the dictionary of the desired section to dynmaically add
        * new list items.
        *
        * This requires the user to name the different bullets as:
        * "Step1", "Step2", ... , "Step101", ... , "Step161803398875"
        *
        * @param numbers - The chosen numbers
        * @return dom - The array of list items.
        *
        */
      var generateOrderedList = function (numbers) {
        var dom = [];
        var i = 0;
        var loop = true;

        while (loop) {
          i++;

          if (LottoDictionary.dictionary().LottoSystem.InfoTab['Step' + i]) {
            dom.push(m('li', d('LottoSystem/InfoTab/Step' + i, { numbers: numbers })));
          } else {
            loop = false;
          }
        }

        return dom;
      };

      // Prepare the dictionary entry:
      var getDictionarySystemName = function () {
        var map = {
          M: 'Mathematic',
          R: 'Reduced',
          C: 'Chance'
        };
        return 'LottoSystem/InfoTab/' + (map[systemType] || '');
      };

      var getDictionaryGameDescription = function (system, numbers, rows) {
        var _system = system.replace(' ', '-');
        var systemSpecific = d('LottoSystem/InfoTab/' + _system, {
          system: system,
          numbers: numbers,
          rows: rows
        });
        if (typeof systemSpecific === 'string') {
          return systemSpecific;
        }
        return d('LottoSystem/InfoTab/GameDescription', {
          system: system,
          numbers: numbers,
          rows: rows
        });
      };


      // View:
      return [
        m('div', { class: 'inner-wrapper' }, [
          m('h2', { class: 'tab-headline' }, d('LottoSystem/InfoTab/Header', { system: system })),
          m('p', d(getDictionarySystemName())),
          m('p', getDictionaryGameDescription(system, numbers, rows))
        ]),
        m('div', { class: 'inner-wrapper' }, [
          m('h2', { class: 'tab-headline' }, d('LottoSystem/InfoTab/ThisIsHowYouPlay', { system: system })),
          m('ol', [
            generateOrderedList(numbers)
          ])
        ])
      ];

    };

    // Public functions:
    return InfoTab;

  });
