defineDs('DanskeSpil/Domain/Lotto/Scripts/Templates/RowNumber',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Template:
    var RowNumber = function (number, className, config) {

      // Variables:
      className = className || '';

      // Return:
      return m('div', { class: 'cell cell-number-' + number + className, config: config }, [
        m('span', { class: 'front-face' }, [
          m('span', { class: 'number' }, number),
        ]),
        m('span', { class: 'back-face' }, [
          m('span', { class: 'number' }, number)
        ])
      ]);

    };

    // Public functions:
    return RowNumber;

  });
