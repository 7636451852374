defineDs('DanskeSpil/Domain/Lotto/Scripts/Campaigns/Components/ChristmasMultiClient', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/SaturdayLottoApi',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo',
  'DanskeSpil/Domain/Lotto/Scripts/Models/LottoGame',
  'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoClassic/DeleteOverlay',
  'DanskeSpil/Domain/NumberGames/Scripts/Helpers/MultiClientDictionary',
  'DanskeSpil/Domain/NumberGames/Scripts/Templates/Shared/PopoverDrawer',
  'DanskeSpil/Domain/NumberGames/Scripts/Components/MultiClient/MultiClient',
  'DanskeSpil/Domain/Lotto/Scripts/Campaigns/Templates/Christmas/ExtraDrawUpsell',
  'DanskeSpil/Domain/Lotto/Scripts/Campaigns/Templates/Christmas/GameMode',
], function (Component, Storage, Ensighten, Event, AccountIntegration, Api, LottoDictionary, LottoInfo, LottoGame, DeleteOverlay, mcDictionary, PopoverDrawer, MultiClient, ExtraDrawUpsell, GameMode) {
  Component('lottosaturday-jul-multi-client', [AccountIntegration, LottoDictionary, LottoInfo, LottoGame], function (m, ignoreRoute, settings, property, element) {
    this.d = LottoDictionary.get;
    this.settings = settings;
    this.property = property;
    this.element = element;
    this.gameType = 'Lotto';
    this.infoFeed = m.prop(LottoInfo.saturday());
    this.isFeedDown = m.prop(LottoInfo.isFeedDown());
    this.getLightningDefaultRowCount = m.prop(this.infoFeed().getLightningDefaultRowCount());
    this.getLightningOptions = m.prop(this.infoFeed().getLightningOptions);
    this.api = function () {
      return Api;
    }.bind(this);
    this.deleteOverlayForClassicRow = DeleteOverlay;

    this.skipCampaignEngineColors = m.prop(true);
    this.campaign = m.prop(settings.campaign);
    this.isCampaignEngineOn = this.settings.useCampaignEngine;
    if (this.isCampaignEngineOn) {
      this.campaign(null);
    }

    var setItem = mcDictionary.setItem.bind(null, this.d);

    this.receiptDictionary = () => {
      if (this.game?.()?.firstDrawNo() !== this.infoFeed().getOpenDraw?.()?.id) {
        return setItem('Campaigns/ChristmasMultiClient/ReceiptStep/AdvanceBuySubtitle')();
      }
      return setItem('Campaigns/ChristmasMultiClient/ReceiptStep/Subtitle')();
    };

    var christmasLottoDictionaries = {
      closedGame: {
        noOpenDrawHeader: setItem('LottoClosed/FrontpageLightning/NoOpenDrawHeader'),
        noOpenDrawText1: setItem('LottoClosed/FrontpageLightning/NoOpenDrawText_1'),
        noOpenDrawText2: setItem('LottoClosed/FrontpageLightning/NoOpenDrawText_2'),
        gameNotAvailableHeader: setItem('LottoClosed/FrontpageLightning/GameNotAvailableHeader'),
        gameNotAvailableText1: setItem('LottoClosed/FrontpageLightning/GameNotAvailableText_1'),
        gameNotAvailableText2: setItem('LottoClosed/FrontpageLightning/GameNotAvailableText_2')
      },
      campaignInformOverlay: {
        title: setItem('Campaigns/ChristmasMultiClient/InformOverlay/Title'),
        body: setItem('Campaigns/ChristmasMultiClient/InformOverlay/Description'),
        confirmButton: setItem('Campaigns/ChristmasMultiClient/InformOverlay/ConfirmButton')
      },
      preGame: {
        helpOverlay: setItem('Campaigns/ChristmasMultiClient/PreGame/HelpOverlay')
      },
      game: {
        descriptionSingle: setItem('Campaigns/ChristmasMultiClient/GameStep/DescriptionSingle'),
        description: setItem('Campaigns/ChristmasMultiClient/GameStep/Description')
      },
      draws: {
        drawSublabelSingleWeekSingleTicket: setItem('Campaigns/ChristmasMultiClient/DrawsStep/DrawSublabel'),
        drawSublabelSingleWeek: setItem('Campaigns/ChristmasMultiClient/DrawsStep/DrawSublabel'),
        drawSublabelSingleTicket: setItem('Campaigns/ChristmasMultiClient/DrawsStep/DrawSublabel'),
        drawSublabel: setItem('Campaigns/ChristmasMultiClient/DrawsStep/DrawSublabel'),
        drawSublabelNoTickets: setItem('Campaigns/ChristmasMultiClient/DrawsStep/DrawSublabelNoTickets')
      },
      joker: {
        wednesdayJokerLabel: setItem('MultiClient/JokerStep/WednesdayJokerLabel'),
        saturdayJokerLabel: setItem('MultiClient/JokerStep/SaturdayJokerLabel'),
        bothJokerLabel: setItem('MultiClient/JokerStep/BothDrawsDaysLabel')
      },
      receipt: {
        subtitle: this.receiptDictionary,
        subscribeReminder: {
          keepRemindMe: setItem('LottoReceiptStep/SubscribeReminder/KeepRemindMe'),
          remindMe: setItem('LottoReceiptStep/SubscribeReminder/RemindMe'),
          errorUserUnauthorized: setItem('LottoReceiptStep/SubscribeReminder/ErrorUserUnauthorized'),
          error: setItem('LottoReceiptStep/SubscribeReminder/Error'),
          successRemindMe: setItem('LottoReceiptStep/SubscribeReminder/SuccessRemindMe'),
          successDontRemindMe: setItem('LottoReceiptStep/SubscribeReminder/SuccessDontRemindMe')
        }
      }
    };

    mcDictionary.setDictionary.bind(this.d)(christmasLottoDictionaries);

    this.campaignFlowSteps = [
      {
        name: 'confirm',
        dependency: {
          path: 'DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/Client/Flow/Confirm'
        },
        options: {
          renderCondition: (currentFlowStepIndex) => {
            if (currentFlowStepIndex === 0) {
              this.game().prepareConfirm()
                .catch(() => {
                  this.goToFlowStep(-1);
                  this.preGameClientStep.active(true);
                  this.showError();
                  m.redraw();
                });

              return true;
            }
          }
        }
      },
      {
        name: 'receipt',
        dependency: {
          path: 'DanskeSpil/Domain/NumberGames/Scripts/Templates/MultiClient/Client/Flow/Receipt',
          lazyLoaded: true
        },
        options: {
          renderCondition: (currentFlowStepIndex) => {
            if (!this._flowSteps || !this._flowSteps?.()[currentFlowStepIndex]) return;
            var currentFlowStep = this._flowSteps()[currentFlowStepIndex].name;
            return currentFlowStep === 'receipt';
          }
        }
      }
    ];

    this.upSellPopover = new PopoverDrawer({ $parent: this.$element, className: 'christmas-upsell-popover' });
    this.upSellCheck = () => {
      const gameContainsExtraDraw = this.game().rawDrawsDataFromInfoFeed().some((draw) => draw.drawType?.toLowerCase() === 'extra');
      const isExtraDrawUpsell = gameContainsExtraDraw && this.game().upsellExtra() !== null;
      const canAddUpsell = (!gameContainsExtraDraw || isExtraDrawUpsell) && !this.isOpenDrawPartOfCampaign();
      const showUpSell = this.campaignExtraDraw && this.game().numberOfDraws() !== 0 && canAddUpsell;
      if (!showUpSell && !this.upSellPopover.isShown()) return;
      this.upSellPopover.setContent(m(ExtraDrawUpsell, { controller: this }));
      Event.subscribe('popover-drawer:shown', (popoverController) => {
        const popoverInnerHeight = popoverController.$container.querySelector('.multi-client__flow-step-inner')?.clientHeight; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        this.$element.style.minHeight = popoverInnerHeight ? `${popoverInnerHeight + 48}px` : null;
      });
      this.upSellPopover.onClose(() => {
        this.$element.style.minHeight = null;
        Event.fire('upsell-popover:closed');
      });
      this.upSellPopover.show();
    };

    this.christmasCampaignOverlayIdentifier = m.prop('christmasCampaignOverlayIdentifier');
    const showCampaignInformOverlay = () => {
      const onConfirm = () => {
        Event.fire('ds.event.accordionInfoOverlay:show', {
          key: 'productchange',
          className: 'accordion-info-overlay__christmas-product-change'
        });
        Storage.set(this.christmasCampaignOverlayIdentifier(), true);
      };
      const onClose = () => {
        Storage.set(this.christmasCampaignOverlayIdentifier(), true);
      };

      if (Storage.get(this.christmasCampaignOverlayIdentifier())) return;
      Event.fire('ds.event.accordionInfoOverlay:show', {
        key: 'julelotto2024',
        onConfirm,
        onClose
      });
      Ensighten.pushGaEvent('julelotto', 'popup_shown', this.game().firstDrawNo() ? 'julelotto' : 'lotto', 'julelotto2024');
    };

    Event.subscribe('multi-client:draws-step-loaded', () => {
      this.christmasCampaignOverlayIdentifier(`christmasCampaignOverlayIdentifier-${this.game().id()}`);
    });

    Event.subscribe('multi-client:joker-step-loaded', (jokerController) => {
      if (this.hasActiveCampaign() &&
        this.game().numberOfDraws() === 1 &&
        this.isOpenDrawPartOfCampaign()) {
        jokerController.toggleWednesdayJoker(false);
        jokerController.toggleSaturdayJoker(false);
        jokerController.game().save();
        jokerController.selectedJoker(false);
        setTimeout(() => {
          jokerController.goToNextFlowStep();
        });
      }
      if (this.hasActiveCampaign() &&
          (this.hasCampaignAsPartOfDraws() && !this.isOpenDrawPartOfCampaign()) &&
          !this.game().upsellExtra()) {
        showCampaignInformOverlay();
      }
      this.upSellCheck();
    });

    Event.subscribe('multi-client:draws-step-on-select', () => {
      if (this.hasActiveCampaign() &&
      this.game().numberOfDraws() === 1 &&
      this.isOpenDrawPartOfCampaign()) {
        this.selectedJoker(null);
        this.game()['withJokerSaturday'](null);
        this.game()['withJokerWednesday'](null);
      }
    });

    const showBuyInOverlay = () => {
      const onConfirm = () => {
        Event.fire('ds.event.accordionInfoOverlay:show', {
          key: 'productchange',
          className: 'accordion-info-overlay__christmas-product-change'
        });
      };
      Event.fire('ds.event.accordionInfoOverlay:show', {
        key: 'julelotto2024-buyin',
        onConfirm
      });
      Ensighten.pushGaEvent('julelotto', 'popup_shown', this.game().firstDrawNo() ? 'julelotto' : 'lotto', 'julelotto2024-buyin');
    };

    Event.subscribe('multi-client:confirm-step-loaded', () => {
      if (this.hasActiveCampaign() &&
          (this.isOpenDrawPartOfCampaign() ||
          this.game().firstDrawNo())) {
        showBuyInOverlay();
      }
    });

    Event.subscribe('multi-client:active-campaign', (campaignData) => {
      if (!campaignData ||
          campaignData.tag.toLowerCase() !== 'christmas') return;
      this.campaignExtraDraw = this.infoFeed().data().draws?.find((draw) => {
        const isOfTypeExtra = draw.drawType?.toLowerCase() === 'extra';
        const hasActiveCampaign = draw.campaignNumbers.some((campaignNumber) => campaignData.campaignNumbers.includes(campaignNumber));
        return isOfTypeExtra && hasActiveCampaign;
      });
      if (this.campaignExtraDraw) {
        this.preGameClientStep = GameMode;
        if (!this.gameSelectorOptions) {
          this.gameSelectorOptions = {
            isVisible: false
          };
        }
      }
    });

    this.flowSteps = () => {
      if (this.game()?.firstDrawNo()) {
        return this.campaignFlowSteps;
      }
      return null;
    };
    this.advanceBuyDraws = m.prop(null);
    this.advanceBuyFirstDraw = m.prop(null);
    this.isThemeOn = m.prop(false);

    this.drawOptionIndicatorIcon = () => {
      if (this.isThemeOn()) {
        return '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#ticket_gold_outline';
      }
      return null;
    };
    this.jokerHatIcon = () => {
      if (this.isThemeOn()) {
        return '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#joker_gold';
      }
      return null;
    };
    this.campaignIcons = () => {
      if (this.isThemeOn()) {
        return {
          campaign: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#normal_campaign_ticket_gold',
          christmasCampaign: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#ticket_gold_outline',
          draws: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#confirm_draw',
          rows: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#confirm_rows',
          joker: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#joker_gold',
          calendar: '/Components/DanskeSpil/Domain/Lotto/Graphics/SpriteSheets/LottoChristmas.svg#confirm_calendar'
        };
      }

      return null;
    };

    // Component
    MultiClient(m, LottoGame, this);
  });
});
