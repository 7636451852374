defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/LottoWinningNumbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/SaturdayLottoApi',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/WednesdayLottoApi',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoUtils',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/WinningNumbers/Drawing',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/FeaturedWinnersApi',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/DatePicker'
  ],
  function (Component, SaturdayLottoApi, WednesdayLottoApi, LottoDictionary, LottoUtils, Drawing, FeaturedWinnersApi, DatePicker) {

    // Component:
    Component('lotto-winning-numbers', [LottoDictionary], function (m, route, settings, property) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.bonusNumbersLotto = m.prop([]);
          this.gameType = m.prop(settings.gameType);
          this.d = LottoDictionary.get;
          this.drawDate = m.prop();
          this.loading = m.prop(true);
          this.prizesJoker = m.prop([]);
          this.prizesLotto = m.prop([{ amount: 0 }]);
          this.superNumberLotto = m.prop([]);
          this.winningNumbersJoker = m.prop([]);
          this.winningNumbersLotto = m.prop([]);
          this.featuredWinners = m.prop([]);
          this.featuredWinnersJoker = m.prop([]);
          this.game = m.prop('LottoSaturday');
          this.jokerGame = m.prop('JokerSaturday');


          this.property = property;
          this.setings = settings;
          this.completedDrawsDates = m.prop([]);
          this.selectedDrawDate = m.prop();
          this.isDatepickerOpened = m.prop(false);


          // Functions:
          this.jokerFiltered = function () {
            var jokerArray = [];
            var prizesJoker = this.prizesJoker();
            var self = this;

            prizesJoker.forEach((value) => {
              value.name = self.d('WinningNumbers/CorrectJokerNumbers/Id' + value.id) + ' ' + self.d('WinningNumbers/CorrectJokerNumbers/CorrectNumber');
              jokerArray.push(value);
            });

            return (jokerArray.length > 0) ? jokerArray : this.prizesJoker();
          }.bind(this);

          this.getWinningNumbers = function () {
            var self = this;

            if (this.gameType() === 'saturday') {
              SaturdayLottoApi.getWinningNumbersLotto({ date: this.drawDate() }).then(function (data) {
                self.winningNumbersLotto(data.lottoSaturday.winningNumbers);
                self.bonusNumbersLotto([data.lottoSaturday.bonusNumber]);
                self.prizesLotto(data.lottoSaturday.prizes);
                self.winningNumbersJoker(data.jokerSaturday.winningNumbers);
                self.prizesJoker(data.jokerSaturday.prizes);

                m.redraw();
              });
              FeaturedWinnersApi.getFeaturedWinners({ date: this.drawDate(), game: this.game() }).then(function (data) {
                self.featuredWinners(data);
                m.redraw();
              });
              FeaturedWinnersApi.getFeaturedWinners({ date: this.drawDate(), game: this.jokerGame() }).then(function (data) {
                self.featuredWinnersJoker(data);
                m.redraw();
              });

            } else {
              WednesdayLottoApi.getWinningNumbersLotto({ date: this.drawDate() }).then(function (data) {
                self.winningNumbersLotto(data.lottoWednesday.winningNumbers);
                self.bonusNumbersLotto(data.lottoWednesday.bonusNumbers);
                self.superNumberLotto([data.lottoWednesday.superNumber]);
                self.prizesLotto(data.lottoWednesday.prizes);
                self.winningNumbersJoker(data.jokerWednesday.winningNumbers);
                self.prizesJoker(data.jokerWednesday.prizes);

                m.redraw();
              });
            }
          }.bind(this);

          this.formatDates = function (dates) {
            var self = this;
            dates.sort().reverse();
            var formattedDates = dates.map(function (date) {
              return {
                name: self.d('WinningNumbers/DateOptionPrefix') + ' ' + LottoUtils.formatISO8601(date + ' 00:00:00', { includeTime: false }),
                value: date
              };
            });
            if (formattedDates.length > 0) {
              this.drawDate(dates[0]);

              this.getWinningNumbers();
            }
            this.loading(false);
          };

          this.prizesFiltered = function () {
            var d = this.d;
            var prizeArray = [];
            var prizesLotto = this.prizesLotto();
            var selectedWinningNumbers;

            if (settings.selectedWinningNumbers) {
              selectedWinningNumbers = settings.selectedWinningNumbers.split(',');
            } else {
              return this.prizesLotto();
            }

            selectedWinningNumbers.forEach((id) => {
              prizesLotto.forEach((value) => {
                if (value.id && value.id == id) {
                  value.name = d('WinningNumbers/CorrectNumbers/Id' + value.id) + ' ' + d('WinningNumbers/CorrectNumbers/CorrectNumber');
                  prizeArray.push(value);
                }
              });
            });

            return prizeArray.length > 0 ? prizeArray : this.prizesLotto();
          }.bind(this);

          this.selectDate = function (date) {
            this.drawDate(date);
            this.selectedDrawDate(date);
            this.getWinningNumbers();
          }.bind(this);

          this.isWinningNumbersReady = function () {
          // Returns true if selected draw is loaded and have winningNumbers
            return !this.loading() && this.winningNumbersLotto() && this.winningNumbersLotto().length > 0;
          }.bind(this);

          // Setup:

          if (this.gameType() === 'saturday') {
            SaturdayLottoApi.getCompletedDrawDates().then(function (dates) {
              this.formatDates(dates);
              this.completedDrawsDates(dates);
              this.selectedDrawDate(dates[0]);
            }.bind(this));
          } else if (this.gameType() === 'wednesday') {
            WednesdayLottoApi.getCompletedDrawDates().then(function (dates) {
              this.completedDrawsDates(dates);
              this.selectedDrawDate(dates[0]);
            }.bind(this));
          }

        },

        view: function (controller) {
          return m('section', { class: 'lotto-section' + (settings.topManchete || settings.bottomManchete ? ' manchete' : '') + (settings.topManchete ? ' top' : '') + (settings.bottomManchete ? ' bot' : '') + ' section-with-dropdown' }, [
            m('a', { class: 'anchor', name: 'lottowinningnumbersspot1' }),
            m('div', { class: 'lotto-content-wrapper' }, [
              m('h2', { class: 'winning-numbers-headline' }, controller.d('WinningNumbers/PrizeHeading')),
              m('p', { class: 'winning-numbers-subheadline' }, controller.d('WinningNumbers/PrizeSubHeading')),
              m('div', { class: 'select-date-section' }, [
                (controller.selectedDrawDate() && (controller.completedDrawsDates().length > 0)) ? DatePicker(controller, true) : null
              ]),

              (function () {
                if (controller.loading()) {
                  return '';
                }

                if (!controller.isWinningNumbersReady()) {
                  return m('div', { class: 'disclaimer-info-text' }, controller.d('WinningNumbers/WinningNumbersNotReady'));
                }

                return [
                  m('div', { class: 'winning-numbers-list' }, [
                    Drawing(controller, {
                      winningNumbers: controller.winningNumbersLotto(),
                      bonusNumbers: controller.bonusNumbersLotto(),
                      superNumber: controller.superNumberLotto(),
                      prizes: controller.prizesFiltered(),
                      winners: controller.featuredWinners(),
                      checkViewport: LottoUtils.elementFullyScrolledInViewport
                    }, 'lotto'),
                    Drawing(controller, {
                      winningNumbers: controller.winningNumbersJoker(),
                      prizes: controller.jokerFiltered(),
                      winners: controller.featuredWinnersJoker(),
                      checkViewport: LottoUtils.elementFullyScrolledInViewport
                    }, 'joker')
                  ]),
                  settings.gameType === ('wednesday') ? m('div', { class: 'disclaimer-info-text' }, controller.d('WinningNumbers/DisclaimerInfoText')) : null
                ];


              })()

            ])
          ]);
        }
      };

      // Setup routes:
      route('/', root);

    });

  });
