defineDs('DanskeSpil/Domain/Lotto/Scripts/Components/LottoGameClosed',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoDictionary',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoInfo'
  ],
  function (Component, LottoDictionary, LottoInfo) {

    // Component:
    Component('lotto-game-closed', [LottoDictionary, LottoInfo], function (m, route, settings) {

      // Feed or noOpenDraw check:
      if (!LottoInfo.isFeedDown() && !LottoInfo.saturday().noOpenDraw()) {

        location.href = '/lotto';

      }

      // Components:
      var root = {
        controller: function () {
          this.d = LottoDictionary.get;
        },

        view: function (controller) {
          return m('div', { class: 'lotto-no-open-draw' }, [
            m('section', { class: 'lotto-section' + (settings.topManchete || settings.bottomManchete ? ' manchete' : '') + (settings.topManchete ? ' top' : '') + (settings.bottomManchete ? ' bot' : '') }, [
              m('div', { class: 'lotto-content-wrapper' }, [
                LottoInfo.isFeedDown() ? [
                  m('h2', { class: 'no-open-draw-header' }, controller.d('LottoClosed/GameNotAvailableHeader')),
                  m('h2', { class: 'no-open-draw-header' }, controller.d('LottoClosed/GameNotAvailableText'))
                ] : [
                  m('h2', { class: 'no-open-draw-header' }, controller.d('LottoClosed/NoOpenDrawHeader')),
                  m('h2', { class: 'no-open-draw-header' }, controller.d('LottoClosed/NoOpenDrawText'))
                ]
              ])
            ])
          ]);
        }

      };

      // Setup routes:
      route('/', root);

    });

  });
