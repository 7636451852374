defineDs('DanskeSpil/Domain/Lotto/Scripts/Templates/LottoSystem/ProfitGuaranteeTab',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Lotto/Scripts/Templates/LottoSystem/Accordion'
  ],
  function (m, Accordion) {

    // Template:
    var ProfitGuaranteeTab = function (controller) {

      // Variables:
      var d = controller.d;
      var game = controller.game();
      var system = game.systemName();
      var systemType = system ? system.trim().slice(0, 1) : null;
      var winningsProbabilities = controller.property('winningsProbabilities');

      if (!winningsProbabilities || winningsProbabilities.system !== system) {
        game.api().getSystemWinningProbabilities(system).then(function (response) {
          controller.property('winningsProbabilities', { data: response, system: system, systemType: systemType });

          m.redraw();
        });
      }

      // Functions:
      var print = function () {
        controller.print(true);
      };

      // View:
      return m('div', { class: 'inner-wrapper' }, [
        m('h2', { class: 'tab-headline' }, d('LottoSystem/ProfitGuaranteeTab/Header', { systemName: system })),
        m('p', d('LottoSystem/ProfitGuaranteeTab/Subheader', { systemName: system })),
        m('div', { class: 'accordions-container' }, [
          m('div', { class: 'print-button', onclick: print }, [
            m('svg', { class: 'icon-print' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-print' })
            ])
          ]),
          m('div', { class: 'accordions-header' }, system),
          function () {
            winningsProbabilities = controller.property('winningsProbabilities');

            if (winningsProbabilities && winningsProbabilities.systemType === systemType) {

              // C & R:
              if (winningsProbabilities.systemType !== 'M') {
                return winningsProbabilities.data.rows.map(function (row, index) {
                  row.index = index;

                  return Accordion(controller, 'winnings', row);
                });

                // M:
              } else {
                var prizes = winningsProbabilities.data.prizes;

                return winningsProbabilities.data.winningNumbers.map(function (number, index) {
                  return Accordion(controller, 'winnings', {
                    additional: winningsProbabilities.data.additionalNumbers[index],
                    index: index,
                    prizes: [prizes.first[index], prizes.second[index], prizes.third[index], prizes.fourth[index], prizes.fifth[index]],
                    number: number
                  });
                });
              }

            }
          }()
        ])
      ]);

    };

    // Public functions:
    return ProfitGuaranteeTab;

  });
