defineDs('DanskeSpil/Domain/Lotto/Scripts/Templates/WinningNumbers/Results',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Lotto/Scripts/Helpers/LottoUtils'
  ],
  function (m, Utils) {

    // Template:
    var Results = function (controller, data, type) {

      // Variables:
      var d = controller.d;

      var head = function () {
        return m('thead', [
          m('tr', [
            m('th', type === 'joker' ? d('WinningNumbers/JokerResultsCorrectNumbers') : d('WinningNumbers/LottoResultsCorrectNumbers')),
            m('th', type === 'joker' ? d('WinningNumbers/JokerResultsWinners') : d('WinningNumbers/LottoResultsWinners')),
            m('th', type === 'joker' ? d('WinningNumbers/JokerResultsPrize') : d('WinningNumbers/LottoResultsPrize'))
          ])
        ]);
      };

      var row = function (data) {
        return m('tr', [
          m('th', data.name),
          m('td', Utils.formatNumber(data.numberOfWinners)),
          m('td', Utils.formatCurrency(data.amount))
        ]);
      };

      var body = function (data) {
        var rows = data.map(function (obj) {
          return row(obj);
        });

        if (type === 'lotto') {
          rows.push(row({
            name: d('WinningNumbers/LottoAdditionalDrawLabel'),
            numberOfWinners: d('WinningNumbers/LottoAdditionalDrawNumberOfWinners'),
            amount: d('WinningNumbers/LottoAdditionalDrawAmount')
          }));
        }

        return m('tbody', rows);
      };

      // Return:
      return m('table', [
        head(),
        body(data)
      ]);

    };

    // Public functions:
    return Results;

  });
